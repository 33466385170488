import { defineStore } from "pinia";
import { Paths, Components } from "@/assets/docs/client.d";

export interface SrfState {
  all: Paths.ListSrfs.Responses.$200 | null;
  details: Paths.GetSrfDetails.Responses.$200 | {};
  productManagers: Paths.GetProductManagersForClient.Responses.$200 | [];
  productParties: Paths.GetProductParties.Responses.$200 | [];
  distributionAgents: Paths.GetDistributionAgentsForClient.Responses.$200 | [];
  static: Paths.GetSrfStaticData.Responses.$200 | null;
  staticUserSpecific: Paths.GetSrfUserSpecificStaticData.Responses.$200 | null;
  payingAgents: Paths.GetPayingAgents.Responses.$200 | [];
  custodiansBrokers: Paths.GetProductCustodiansBrokersForClient.Responses.$200 | [];
  pddDetails: Components.Schemas.G2fpModelsG2DbModelsProductDueDiligence | {};
  availableTraders: Paths.AvailableTraders.Responses.$200 | null;
  pddDocumentTypes: Paths.GetPddDocumentTypes.Responses.$200 | null;
  pddLegalSubjects: Components.Schemas.G2fpAppWebModelsLegalSubjectTypeVm | null;
  pddRequiredDocuments: Components.Schemas.G2fpAppWebModelsRequiredDocumentVm | null;
  pddAdditionalDocuments: Components.Schemas.G2fpAppWebModelsAdditionalDocumentVm | null;
  pddStoredDocumentTypes: Components.Schemas.G2fpAppWebModelsStoredDocumentTypeVm | null;
}

export const useSrfStore = defineStore("SrfStore", {
  state: (): SrfState => ({
    all: null,
    details: {},
    productManagers: [],
    productParties: [],
    distributionAgents: [],
    static: null,
    staticUserSpecific: null,
    payingAgents: [],
    custodiansBrokers: [],
    pddDetails: {},
    availableTraders: null,
    pddDocumentTypes: null,
    pddLegalSubjects: null,
    pddRequiredDocuments: null,
    pddAdditionalDocuments: null,
    pddStoredDocumentTypes: null,
  }),

  debounce: {
    load: [250, { before: true }],
    loadStatic: [250, { before: true }],
    loadStaticUserSpecific: [250, { before: true }],
  },

  actions: {
    setSrfList(srfList) {
      this.all = srfList;
    },
    setSrf(srf) {
      this.details = srf;
    },
    setPDD(pdd) {
      this.pddDetails = pdd;
    },
    setPddRequiredDocuments(data) {
      this.pddLegalSubjects = data.legalSubjects;
      this.pddRequiredDocuments = data.requiredDocuments;
      this.pddAdditionalDocuments = data.additionalDocuments;
      this.pddStoredDocumentTypes = data.storedDocumentTypes;
    },
    reset() {
      this.all = null;
      this.details = {};
      this.productManagers = [];
      this.productParties = [];
      this.distributionAgents = [];
      this.static = null;
      this.staticUserSpecific = null;
      this.payingAgents = [];
      this.custodiansBrokers = [];
      this.pddDetails = {};
      this.availableTraders = null;
      this.pddDocumentTypes = null;
      this.pddLegalSubjects = null;
      this.pddRequiredDocuments = null;
      this.pddStoredDocumentTypes = null;
    },

    async loadAll(payload) {
      const response = await this.apiClient.ListSrfs(payload, null, {
        timeout: 300000,
      });
      this.setSrfList(response.data.srfList);
    },
    async load(id, isLite = false) {
      if (this.details.id && id !== this.details.id) {
        this.details = {};
        this.pddDetails = {};
        this.pddRequiredDocuments = null;
      }

      if (id === "new") {
        id = sessionStorage.getItem("Srf.createdId") || -2; //backend default dummy id
      }

      const response = await this.apiClient.GetSrfDetails({ id: id, isLite: isLite });

      if (response.status === 200) {
        this.setSrf(response.data);
        this.setPDD(response.data.productDueDiligence);
      } else {
        return Promise.reject(response);
      }
    },
    async loadRefresh(id, isLite = false) {
      const response = await this.apiClient.GetSrfDetails({ id: id, isLite: isLite });
      this.setSrf(response.data);
    },
    async create({
      version,
      loadSrf,
      isLite,
    }: {
      version: number;
      loadSrf: boolean;
      isLite: boolean;
    }) {
      this.details = {};
      const response = await this.apiClient.CreateSrf(
        {
          isLite: isLite,
        },
        version ?? 2,
      );

      sessionStorage.setItem("Srf.createdId", response.data.id);

      if (loadSrf) {
        this.load(response.data.id, isLite);
      } else {
        this.setSrf(response.data);
      }

      return response.data;
    },
    async upsertStep(stepFormData) {
      return await this.apiClient.UpsertSrfStep(null, stepFormData);
    },
    async updateCurrentStep(stepData) {
      return await this.apiClient.UpdateCurrentStep(null, stepData);
    },
    async markComplete(srfId) {
      return await this.apiClient.MarkSrfAsComplete(null, JSON.stringify(srfId));
    },
    async deleteSteps(steps) {
      return await this.apiClient.DeleteSrfSteps(null, steps);
    },
    async loadProductManagers() {
      const response = await this.apiClient.GetProductManagersForClient();
      this.productManagers = response.data;
    },
    async loadDistributionAgents() {
      const response = await this.apiClient.GetDistributionAgentsForClient();
      this.distributionAgents = response.data;
    },
    async loadStatic() {
      if (!this.static) {
        const response = await this.apiClient.GetSrfStaticData();
        this.static = response.data;
      }
    },
    async loadStaticUserSpecific() {
      const response = await this.apiClient.GetSrfUserSpecificStaticData();
      this.staticUserSpecific = response.data;
    },
    async loadPayingAgents() {
      const response = await this.apiClient.GetPayingAgents();
      this.payingAgents = response.data;
    },
    async loadCustodiansBrokers() {
      const response = await this.apiClient.GetProductCustodiansBrokersForClient();
      this.custodiansBrokers = response.data;
    },
    async loadAvailableTraders(id) {
      const response = await this.apiClient.AvailableTraders(id);
      this.availableTraders = response.data;
    },
    async loadPddDocumentTypes() {
      const response = await this.apiClient.GetPddDocumentTypes();
      this.pddDocumentTypes = response.data;
    },
    async loadPddRequiredDocuments(srfId) {
      const response = await this.apiClient.RequiredDocuments(srfId);
      this.setPddRequiredDocuments(response.data);
    },
    async archive(id) {
      return await this.apiClient.ArchiveSrf(null, id);
    },
    async clonelegacy(id) {
      return await this.apiClient.CloneSrfLegacy(id);
    },
    async clone(id) {
      return await this.apiClient.CloneSrf(id);
    },
    async delete(id) {
      return await this.apiClient.DeleteSrf(null, id);
    },
    async createProductInPortal(id) {
      return await this.apiClient.CreateSrfProduct(id);
    },
    async detectProductType(id) {
      return await this.apiClient.DetectProductType(id);
    },
    async updateProductDueDiligence({ srfId, pdd, formData }) {
      const response = await this.apiClient.ProductDueDiligenceUpdate(srfId, pdd, formData);
      this.setPDD(response.data.productDueDiligence);
      return response;
    },
    async uploadFile({
      srfId,
      entityId,
      documentType,
      file,
      expiryDate,
      documentDate,
      description,
    }) {
      const formData = new FormData();

      if (entityId) {
        formData.append("entityId", entityId);
      }
      if (documentType) {
        formData.append("documentType", documentType);
      }
      if (file) {
        formData.append("file", new File([file], file.name, { type: file.type }));
      }
      if (expiryDate) {
        formData.append("expiryDate", expiryDate);
      }
      if (documentDate) {
        formData.append("documentDate", documentDate);
      }
      if (description) {
        formData.append("description", description);
      }

      const response = await this.apiClient.ProductDueDiligenceUploadFile(srfId, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response;
    },
    async getUploadedFile(payload) {
      return await this.apiClient.ProductDueDiligenceFileDownload(payload);
    },
    async submitProductDueDiligence(srfId, payload) {
      return await this.apiClient.ProductDueDiligenceSubmit(srfId, payload);
    },
    async approveProductDueDiligence(srfId) {
      return await this.apiClient.ProductDueDiligenceApprove(srfId);
    },
    async createEntityApprovalWorkflow({ srfId, productId, update = true }) {
      const response = await this.apiClient.InitiateEntityApprovalFlow(null, {
        srfId,
        assignAllToInitiator: false,
        productId,
      });

      if (response.status === 200) {
        const flowId = response.data;

        this.pddDetails.serializedData.flowId = flowId;
        this.pddDetails.serializedData.isWorkflowComplete = false;
        this.pddDetails.serializedData.isWorkflowTerminated = false;

        if (update) {
          this.updateProductDueDiligence({
            srfId: srfId,
            pdd: this.pddDetails,
            ...this.pddDetails.serializedData,
          });
        } else {
          return flowId;
        }
      }
    },
    async notifyWorkflow(workflowId) {
      return await this.apiClient.NotifyEntityApprovalWorkflow({ workflowId });
    },
    async finalizeWorkflow(workflowId) {
      const wfVm = await this.apiClient.GetWorkflowVmById(workflowId);
      const pendingEvent =
        wfVm.data.workflow.data.pendingEvent ||
        wfVm.data.workflow.data.pendingStage?.pendingEvents?.[0];

      const response = await this.apiClient.PostWorkflowEvent(null, {
        eventName: pendingEvent?.eventName,
        eventDescription: pendingEvent?.eventDescription,
        eventKey: pendingEvent?.eventKey,
        eventData: {},
      });

      return response;
    },
    async createEntityInPortal({ srfId, payload }) {
      return await this.apiClient.CreateEntityInPortal(srfId, payload);
    },
    async getEntityStatus(payload) {
      return await this.apiClient.GetEntityStatus(payload);
    },
    async getProductParties(payload) {
      const response = await this.apiClient.GetProductParties(payload);
      this.productParties = response.data;
    },
  },
});
