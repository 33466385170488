import { AccountType, BatchStatus } from "@/components/workflow/PaymentFlow/Types";
import { useWorkflowStore } from "@/stores/workflow";
import { State, WorkflowType, WorkflowTypeSlug } from "@/components/workflow/Types";
import { invert, isEmpty } from "lodash";
import { useProductStore as productStore } from "@/stores/product";

export default {
  methods: {
    workflowEventDisplayName({
      workflowDefinitionId,
      isFinalTermsheet,
      isDecrease,
      paymentFlowDescription,
    }) {
      let displayName = "";

      switch (workflowDefinitionId) {
        case WorkflowType.TermsheetApproval:
          if (isFinalTermsheet) {
            displayName = "Final Terms";
          } else {
            displayName = "Indicative Terms";
          }
          break;
        case WorkflowType.SecondaryIncreaseDecrease:
          if (isDecrease) {
            displayName = "Sell Order";
          } else {
            displayName = "Buy Order";
          }
          break;
        case WorkflowType.PaymentInstruction:
          displayName = paymentFlowDescription;
          break;
        case WorkflowType.ProductTermination:
          displayName = "Product Termination";
          break;
      }

      return displayName;
    },
    hasCompany(event) {
      return event?.eventDescription?.search("-") > -1;
    },
    generateGuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
    roundTo(n, digits) {
      let negative = false;
      if (digits === undefined) {
        digits = 0;
      }
      if (n < 0) {
        negative = true;
        n = n * -1;
      }
      let multiplicator = Math.pow(10, digits);
      n = parseFloat((n * multiplicator).toFixed(11));
      n = (Math.round(n) / multiplicator).toFixed(2);
      if (negative) {
        n = (n * -1).toFixed(2);
      }
      return n;
    },
    getDistributionAgentDisplayName(distributionAgentParty) {
      let name = distributionAgentParty.party.name;
      if (distributionAgentParty.distributionCountries) {
        name += " (" + distributionAgentParty.distributionCountries + ")";
      }
      return name;
    },
    getAccountType(accountTypeId) {
      switch (accountTypeId) {
        case AccountType.CLIENT:
          return "Client";
        case AccountType.PAYING_AGENT:
          return "Paying Agent";
        case AccountType.CUSTODIAN:
          return "Custodian";
        case AccountType.CRYPTO:
          return "Crypto";
        case AccountType.UNDERLYING_INVESTMENT:
          return "Underlying Investment";
        case AccountType.BACK_CLIENT:
          return "Bank Client";
        case AccountType.PARTY:
          return "Party";
        case AccountType.ISSUER:
          return "Issuer";
        default:
          return "";
      }
    },
    getWorkflowTypeUrl(workflowDefinitionId) {
      return WorkflowTypeSlug[invert(WorkflowType)[workflowDefinitionId]];
    },
    getWorkflowType(workflowTypeUrl) {
      return WorkflowType[invert(WorkflowTypeSlug)[workflowTypeUrl]];
    },
    isTemplateDecrease(template) {
      return template?.orders?.length > 0 ? template.orders[0].nominalUnits < 0 : null;
    },
    getTemplateType(template) {
      if (template.workflowType === WorkflowType.PrimaryBookingRequest) {
        return "Primary Booking Request";
      } else if (template.workflowType === WorkflowType.SecondaryIncreaseDecrease) {
        const isDecrease = this.isTemplateDecrease(template);
        if (isDecrease) {
          return "Secondary Decrease";
        } else if (isDecrease === null) {
          return "Secondary Increase/Decrease";
        } else {
          return "Secondary Increase";
        }
      } else {
        // Unknown type
        return "";
      }
    },
    async handleFilesUpload(files, fileType, flowId) {
      if (files?.length > 0) {
        const uploadFilesResult = await Promise.all(
          files.map(file =>
            this.workflowStore.uploadFile({
              file: file,
              fileType: fileType,
              flowId: flowId,
            }),
          ),
        );

        if (
          uploadFilesResult.length > 0 &&
          uploadFilesResult.every(
            uploadedFile =>
              uploadedFile.status === 200 && uploadedFile.guid && uploadedFile.guid.length > 0,
          )
        ) {
          return { guids: uploadFilesResult.map(uploadedFile => uploadedFile.guid) };
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    searchFields(fields, searchQuery) {
      return fields.some(field => field?.toLowerCase().includes(searchQuery));
    },
    filterByField(field, filter) {
      return filter === "All" || field === filter;
    },
    filterByUser(workflow, user, filterEnabled) {
      return (
        !filterEnabled ||
        (filterEnabled &&
          (workflow.initiatorUserName === user.userName ||
            workflow.data.processedEvents?.find(
              event => event.eventData?.approvedBy === user.userName,
            ) ||
            workflow.data.allEvents?.find(event => event.eventData?.approvedBy === user.userName)))
      );
    },
    productLCEDisabled(product) {
      return product?.disableLifecycleEvents === true;
    },
    getBatchStatus(status) {
      switch (status) {
        case BatchStatus.NotStarted:
          return "Not Started";
        case BatchStatus.Draft:
          return "Draft";
        case BatchStatus.Pending:
          return "Pending";
        case BatchStatus.Retrying:
          return "Retrying";
        case BatchStatus.Cancelled:
          return "Cancelled";
        case BatchStatus.CompletedWithFailure:
          return "Completed with Failure";
        case BatchStatus.Completed:
          return "Completed";
        case BatchStatus.InteractionError:
          return "Interaction Error";
        case BatchStatus.Failed:
          return "Failed";
        case BatchStatus.CancellationError:
          return "Cancellation Error";
        default:
          return "";
      }
    },
    getBatchStatusSeverity(status) {
      switch (status) {
        case BatchStatus.NotStarted:
        case BatchStatus.Draft:
        case BatchStatus.Pending:
        case BatchStatus.Retrying:
          return "warning";
        case BatchStatus.CompletedWithFailure:
        case BatchStatus.Completed:
          return "success";
        case BatchStatus.Cancelled:
        case BatchStatus.InteractionError:
        case BatchStatus.Failed:
        case BatchStatus.CancellationError:
          return "danger";
        default:
          return "";
      }
    },
  },
  computed: {
    workflowStore() {
      return useWorkflowStore();
    },
    workflow() {
      return this.workflowStore?.info;
    },
    workflowStatus() {
      return {
        isTerminated: this.workflow?.status === State.TERMINATED,
        isComplete: this.workflow?.status === State.COMPLETED,
        isCancelled: this.workflow?.data?.isCancelled,
        isRejected: this.workflow?.data?.isCancelled && this.workflow?.status === State.COMPLETED,
        isFrozen: this.isFrozen,
      };
    },
    product() {
      return !isEmpty(productStore()?.info) ? productStore()?.info : this.workflowStore.product;
    },
    productPayingAgentAccounts() {
      return this.product?.accounts?.filter(a => a.account.accountTypeId === AccountType.PAYING_AGENT);
    },
    isFrozen() {
      if (
        (this.product !== null && this.workflow?.workflowDefinitionId === WorkflowType.Coupon) ||
        this.workflow?.workflowDefinitionId === WorkflowType.OrderAcceptanceTemplate ||
        this.workflow?.workflowDefinitionId === WorkflowType.SecondaryIncreaseDecrease ||
        this.workflow?.workflowDefinitionId === WorkflowType.ProductTermination ||
        this.workflow?.workflowDefinitionId === WorkflowType.PartialRedemptionEvent
      ) {
        return this.productLCEDisabled(this.product);
      }
      return false;
    },
    lastRoute() {
      const lastPage = this.$router.options.history.state.back;
      return lastPage ? this.$router.resolve(lastPage) : null;
    },
    fromProduct() {
      return this.lastRoute?.name === "Product" && this.lastRoute?.params?.isin;
    },
    fromWorkflows() {
      return this.lastRoute?.path.includes("workflows") && !!this.lastRoute?.params?.type;
    },
    backRoute() {
      return this.fromProduct || this.fromWorkflows
        ? this.lastRoute
        : {
            name: "Workflows",
            params: {
              type:
                this.$route?.params?.type ||
                this.getWorkflowTypeUrl(this.workflow?.workflowDefinitionId),
              ...(this.lastRoute?.name === "NewWorkflow" && { status: "pending" }),
            },
          };
    },
  },
};
