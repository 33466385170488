function getTitle(vm) {
  const { title } = vm.$options;
  if (title) {
    return typeof title === "function" ? title.call(vm) : title;
  }
}
export default {
  created() {
    this.pageTitle = document.title;
    this.setTitle();
  },
  updated() {
    this.setTitle();
  },
  methods: {
    setTitle() {
      const title = getTitle(this);

      if (title) {
        document.title = `${this.$root.pageTitle} / ${title}`;
      }
    },
  },
};
