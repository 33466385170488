import { OpenAPIClientAxios } from "openapi-client-axios";
import { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import definition from "@/assets/docs/api.json";
import { Client } from "@/assets/docs/client.d";

const config: AxiosRequestConfig = {
  timeout: 120 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
};

export const apiConfig = {
  definition,
  axiosConfigDefaults: config,
};

export default {
  install: app => {
    const api = new OpenAPIClientAxios(apiConfig);

    const client = api.initSync<Client>();
    client.defaults.headers.common["Content-Type"] = "application/json";
    axiosRetry(client, {
      retries: 3,
      retryDelay: axiosRetry.exponentialDelay,
    });
    app.config.globalProperties.$apiClient = client;
  },
};
