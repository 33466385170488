import { defineStore } from "pinia";
import { useAccountStore } from "./account";
import { useWorkflowStore } from "./workflow";
import { ProductState as ProdState } from "@/components/product/Types";
import { Paths } from "@/assets/docs/client.d";
import contentDispositionParser from "content-disposition-parser";

export interface ProductState {
  all: Paths.GetProductList.Responses.$200 | null;
  workflowAll: Paths.GetAllWorkflowProducts.Responses.$200 | null;
  info: Paths.GetProductDetailsByIsin.Responses.$200 | {};
  relatedWorkflows: Paths.GetWorkflowsByProductIsin.Responses.$200 | null;
  invoices: Paths.ListProductInvoices.Responses.$200 | null;
  valuationHistory: Paths.GetValuationHistoryForProduct.Responses.$200 | null;
  termsAndConditions: Paths.GetTermsAndConditionsForProduct.Responses.$200 | null;
  hasTermsheet: Paths.HasTermsheetForAProduct.Responses.$200 | null;
  availableStates: Array<any> | null;
  issuer: Paths.GetIssuer.Responses.$200 | {};
}

export const useProductStore = defineStore("ProductStore", {
  state: (): ProductState => ({
    all: null,
    workflowAll: null,
    info: {} as Paths.GetProductDetailsByIsin.Responses.$200,
    relatedWorkflows: null,
    invoices: null,
    valuationHistory: null,
    termsAndConditions: null,
    hasTermsheet: null,
    availableStates: null,
    issuer: {},
  }),

  debounce: {
    load: [250, { before: true }],
  },

  actions: {
    setProducts(data) {
      this.availableStates = data.productStates.filter(x => x.id !== ProdState.Cancelled);
      this.all = data.products;
    },
    setWorkflowProducts(products) {
      this.workflowAll = products;
    },
    setProduct(productInfo) {
      this.info = productInfo;
    },
    reset() {
      this.info = {};
      this.relatedWorkflows = null;
      this.invoices = null;
      this.valuationHistory = null;
      this.termsAndConditions = null;
      this.hasTermsheet = null;
      this.issuer = {};
    },

    async loadAll() {
      const response = await this.apiClient.GetProductList(null, null, {
        timeout: 300000,
      });
      this.setProducts(response.data);
    },
    async loadProductsInterestedIn(productStates) {
      const response = await this.apiClient.GetInterestedInProductList(
        { productStates: { ...productStates } },
        null,
        {
          timeout: 300000,
        },
      );

      return response?.data?.products;
    },
    async loadAllByIssuer(issuerId) {
      const response = await this.apiClient.GetProductListByIssuer(issuerId, null, {
        timeout: 300000,
      });
      this.setProducts(response.data);
    },
    async loadWorkflowProducts() {
      const issuedStatusId = 1;
      const workflowStore = useWorkflowStore();
      const workflowResponse = await this.apiClient.GetAllWorkflowProducts(null, null, {
        timeout: 300000,
      });

      if (!workflowStore.couponFlowTypes) {
        await workflowStore.getAvailableCouponFlowTypes();
      }

      if (workflowStore.info.workflowDefinitionId === "CouponFlow") {
        const availableCouponTypes = workflowStore.couponFlowTypes?.options.map(x => x.value);

        const workflowProducts = (workflowResponse.data as []).filter(
          product =>
            availableCouponTypes.find(c => c === product.couponTypeId) &&
            product.productStateId === issuedStatusId,
        );

        this.setWorkflowProducts(workflowProducts);
      } else {
        this.setWorkflowProducts(workflowResponse.data);
      }
    },
    async load(payload, forceReset) {
      if ((this.info.isin && payload.isin !== this.info.isin) || forceReset) {
        await this.reset();
      }
      const response = await this.apiClient.GetProductDetailsByIsin(payload);

      if (response.status === 200) {
        this.hasTermsheetForAProduct({
          productId: response.data.id,
          checkForAllowPotentialInvestor: payload.checkForAllowPotentialInvestor,
        });
        this.setProduct(response.data);
      }
    },
    async loadDashboardProduct(payload, forceReset) {
      if ((this.info.isin && payload.isin !== this.info.isin) || forceReset) {
        await this.reset();
      }
      const response = await this.apiClient.GetProductDetailsDashboard(payload);

      if (response.status === 200) {
        this.hasTermsheetForAProduct({
          productId: response.data.id,
          checkForAllowPotentialInvestor: payload.checkForAllowPotentialInvestor,
        });
        this.setProduct(response.data);
      }
    },
    async loadWorkflows(isin) {
      this.relatedWorkflows = null;

      const response = await this.apiClient.GetWorkflowsByProductIsin(isin, null, {
        timeout: 300000,
      });

      this.relatedWorkflows = (response.data as any).flows;
    },
    async loadInvoices(productId) {
      this.invoices = null;

      const response = await this.apiClient.ListProductInvoices(productId);

      this.invoices = (response.data as any).invoices;
    },
    async loadValuationHistory(payload) {
      this.valuationHistory = null;

      const response = await this.apiClient.GetValuationHistoryForProduct(payload);
      if (response.data) {
        this.valuationHistory = response.data;
      } else {
        this.valuationHistory = [];
      }
    },
    async loadTermsAndConditions(isin) {
      this.termsAndConditions = null;

      const response = await this.apiClient.GetTermsAndConditionsForProduct(isin);

      this.termsAndConditions = response.data;
    },
    async reportInvalidData(reportDescription) {
      const response = await this.apiClient.SendClientFeedback(null, {
        feedback: reportDescription,
        userName: useAccountStore().user.userName,
        productId: this.info.id,
      });

      return response;
    },
    async hasTermsheetForAProduct(payload) {
      this.hasTermsheet = null;

      const response = await this.apiClient.HasTermsheetForAProduct(payload);

      this.hasTermsheet = response.data;
    },
    async getProductSheetForAllIssuers() {
      const response = await this.apiClient.GetProductSheetForAllIssuers(null, null, {
        responseType: "blob",
      });

      return {
        ...response,
        fileData: contentDispositionParser(response.headers["content-disposition"]),
      };
    },
    async getProductSheetForIssuer(issuerId: Paths.GetProductSheetForIssuer.Parameters.IssuerId) {
      const response = await this.apiClient.GetProductSheetForIssuer(issuerId, null, {
        responseType: "blob",
      });

      return {
        ...response,
        fileData: contentDispositionParser(response.headers["content-disposition"]),
      };
    },
    async loadIssuer(issuerId: Paths.GetIssuer.Parameters.IssuerId) {
      this.issuer = {};

      const response = await this.apiClient.GetIssuer(issuerId);

      this.issuer = response.data;
    },

    async createProductEvent(productEvent: Paths.CreateProductEvent.RequestBody) {
      return await this.apiClient.CreateProductEvent(null, productEvent);
    },

    async updateProductVisibilityOnDashboard(
      id: number,
      isVisibleOnDashboard: Paths.UpdateProductVisibilityOnDashboard.RequestBody,
    ) {
      return await this.apiClient.UpdateProductVisibilityOnDashboard(id, isVisibleOnDashboard);
    },
    async productChangeRequest(
      isin: Paths.ProductChangeRequest.Parameters.Isin,
      payload: Paths.ProductChangeRequest.RequestBody,
    ) {
      return await this.apiClient.ProductChangeRequest(isin, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  },
});
